<template>
    <div id="sidebar" class="leaflet-sidebar collapsed">
        <!-- nav tabs -->
        <div class="leaflet-sidebar-tabs">
            <!-- top aligned tabs -->
            <ul role="tablist">
                <li ref="tab-simulation" @click="tabChanged('simulation')">
                    <a href="#simulation" role="tab">
                        <i class="fa fa-play mr-1"></i>
                    </a>
                </li>
                <li ref="tab-sensors" @click="tabChanged('sensors')">
                    <a href="#sidebar-sensors" role="tab">
                        <i class="fa fa-bars"></i>
                    </a>
                </li>
            </ul>

            <!-- bottom aligned tabs
            <ul role="tablist">
                <li>
                    <a>
                        <img class="customerLogo" src="https://afatek.com.tr/wp-content/uploads/2021/01/afatek-logo-2x.png" alt="Logo">
                    </a>
                </li>
            </ul>
            -->
        </div>

        <!-- panel content -->
        <div class="leaflet-sidebar-content">
            <div class="leaflet-sidebar-pane" id="simulation">
                <h1 class="leaflet-sidebar-header">
                    Simülasyon
                    <span class="leaflet-sidebar-close">
                        <i class="fa fa-caret-left"></i>
                    </span>
                </h1>
                <div class="sidebar-content">
                    <div class="row mt-5rem">
                        <start-simulation/>
                    </div>
                    <div class="row mt-5rem" v-if="messages.length">
                        <div class="overflow-x-auto">
                            <table class="w-full whitespace-nowrap">
                                <tbody>
                                    <tr :tabindex="id" class="focus:outline-none text-sm leading-none h-16" v-for="(message, id) in reversedMessages">
                                        <td class="w-1/2">
                                            <div class="flex items-center">
                                                <div class="w-10 h-10 bg-gray-700 rounded-sm flex items-center justify-center" :style="`background-color: ${getColorFromLegend(message.heat)}`">
                                                    <i class="fa-solid fa-triangle-exclamation text-white"></i>
                                                </div>
                                                <div class="pl-2">
                                                    <p class="text-sm font-medium leading-none text-gray-800 text-left">Cihaz {{ message.id }} sıcaklığı {{ message.increase }}℃ arttı.</p>
                                                    <p class="text-xs leading-3 mt-2 italic text-left">{{ $moment(message.time).format('DD.MM.YYYY HH:mm:ss') }}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="leaflet-sidebar-pane" id="sidebar-sensors">
                <h1 class="leaflet-sidebar-header">
                    Sensörler
                    <span class="leaflet-sidebar-close">
                        <i class="fa fa-caret-left"></i>
                    </span>
                </h1>
                <div class="sidebar-content">
                    <div class="mr-3 xl:w-4/4 2xl:w-5/5 w-full">
                        <div class="bg-white pb-5">
                            <div class="overflow-x-auto">
                                <table class="w-full whitespace-nowrap">
                                    <tbody>
                                        <tr :tabindex="id" class="focus:outline-none text-sm leading-none h-16 hover:bg-gray-200 cursor-pointer" @click="showDevice(device)" v-for="(device, id) in sortedDevices">
                                            <td class="w-1/2">
                                                <div class="flex items-center">
                                                    <div class="w-10 h-10 bg-gray-700 rounded-sm flex items-center justify-center" :style="`background-color: ${getColorFromLegend(device.heat)}`">
                                                        <p class="text-xs font-bold leading-3 text-white">{{ device.heat }} ℃</p>
                                                    </div>
                                                    <div class="pl-2">
                                                        <p class="text-sm font-medium leading-none text-gray-800 text-left">ID: {{ device.id }}</p>
                                                        <p class="text-xs leading-3 mt-2 italic">Son güncellenme: {{ getLastUpdated(device) }}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td v-if="getLastIncrease(device) > 0">
                                                <p class="pl-16 bold">+ {{ getLastIncrease(device) }}℃</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>  
    </div>
</template>

<script>
import StartSimulation from '../../../components/StartSimulation.vue'
/* eslint-disable */
export default {
    props: ['messages', 'legend', 'devices'],

    components: {
        StartSimulation
    },

    data(){
        return {
            sidebar: null,
            options: {
                autopan: false,       // whether to maintain the centered map point when opening the sidebar
                closeButton: true,    // whether t add a close button to the panes
                container: 'sidebar', // the DOM container or #ID of a predefined sidebar container that should be used
                position: 'left',     // left or right
            }
        }
    },

    computed: {
        sortedDevices(){
            return this.devices.sort((a, b) => b.heat - a.heat)
        },

        reversedMessages(){
            return this.messages.slice().reverse()
        }
    },

    watch: {
        '$store.state.map.map': function(newVal, oldVal){
            this.sidebar = L.control.sidebar(this.options).addTo(newVal);
            this.$store.commit('map/setSidebarObject', this.sidebar);
        }
    },

    methods: {
        tabChanged(tab){
            console.log('tab changed', tab);
        },

        getColorFromLegend(value){
            let color = '#000000';
            for (let i = 0; i < this.legend.length; i++) {
                if (value >= this.legend[i].minimum) {
                    color = this.legend[i].color;
                    break;
                }
            }
            return color;
        },

        getLastUpdated(device){
            if (device.events.length) {
                let last = device.events.slice().sort((a, b) => b.time - a.time)[0];
                return this.$moment(last.time).fromNow();
            }
            else {
                return this.$moment(device.lastUpdated).fromNow();
            }
        },

        getLastIncrease(device){
            if (device.events.length) {
                let last = device.events.slice().sort((a, b) => b.time - a.time)[0];
                return last.increase;
            }
            else {
                return 0;
            }
        },

        showDevice(device){
            this.$emit('showDevice', device);
        }
    }
}
</script>

<style>
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta2/css/all.min.css);

#sidebar {
    margin: 1rem;
}
.mt-5rem {
    margin-top: 5rem;
}
/*
.customerLogo {
    width: 35px;
    height: 35px;
}
*/
.iconImg {
    width: 30px;
    height: 30px;
    margin-left: 2px;
}
</style>

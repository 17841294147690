<template>
    <div class="info">
        <div class="row body p-3" v-if="messages.length">
            <h2 class="mb-2 text-lg font-semibold text-gray-900 text-left">Gelişmeler</h2>
            <ul class="space-y-1 max-w-md list-inside text-gray-500 dark:text-gray-400">
                <li class="flex items-center" v-for="(message, id) in messages" :key="id">
                    <!--
                    <svg class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0" :fill="getColorFromLegend(message.heat)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                    </svg>
                    -->
                    <i class="w-4 h-4 mr-1.5 fa-solid fa-triangle-exclamation" :style="`color: ${getColorFromLegend(message.heat)}`"></i>
                    
                    Cihaz {{ message.id }} sıcaklığı {{ message.increase }}℃ arttı
                </li>
            </ul>
        </div>
        <div v-else>
            <p class="text-gray-500 dark:text-gray-400">Henüz bir gelişme yok.</p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['legend', 'messages'],

    methods: {
        getColorFromLegend(value){
            let color = '#000000';
            for (let i = 0; i < this.legend.length; i++) {
                if (value >= this.legend[i].minimum) {
                    color = this.legend[i].color;
                    break;
                }
            }
            return color;
        },
    }
}
</script>

<style scoped>
.info {
    background-color: rgba(255, 255, 255, 0.95);
    /*padding: 20px;*/
    width: 300px;
    word-break: break-all;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
}
.body {
    max-height: 200px;
    overflow-y: scroll;
}
</style>
export default {
    namespaced: true,
    state: {
        map: null,
        sidebar: {
            currentTab: '',
            object: null
        },
        simulation: {
            active: false
        }
    },
    getters: {
        getMap: state => {
            return state.map
        },

        getSidebarObject: state => {
            return state.sidebar.object;
        },
        
        getSimulationActive: state => {
            return state.simulation.active;
        }
    },
    mutations: {
        setMap: (state, map) => {
            state.map = map;
        },

        setCurrentTab: (state, tab) => {
            state.sidebar.currentTab = tab; 
        },

        setSidebarObject: (state, object) => {
            state.sidebar.object = object; 
        },

        setSimulationActive: (state, active) => {
            state.simulation.active = active;
        }
    },
    actions: {},
}

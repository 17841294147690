<template>
  <l-map
      :zoom="zoom"
      :center="center"
      :options="options"
      @ready="mapReady"
    >
      <l-tile-layer :url="tileLayer.url" :subdomains="tileLayer.subdomains" />
      
      <sidebar :messages="messages" :legend="legend" :devices="devices" @showDevice="showDevice"/>
      <l-control v-if="infoMessages.length" position="topright">
          <info :legend="legend" :messages="infoMessages"/>
      </l-control>
      <l-control position="bottomright">
        <heats-legend :legend="legend" :devices="devices"/>
        <!--
          <info @showTrucks="showTrucks"/>
        -->
      </l-control>
    </l-map>
</template>

<script>
/* eslint-disable */
import { LMap, LTileLayer , LControl } from 'vue2-leaflet'
import Sidebar from './map/sidebar/Index'
import HeatsLegend from './map/HeatsLegend'
import Info from './map/Info'
import 'leaflet/dist/leaflet.css'
import "leaflet-sidebar-v2"
import "leaflet-sidebar-v2/css/leaflet-sidebar.css"
import "leaflet.markercluster"
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet.markercluster/dist/MarkerCluster.Default.css"

export default {
  components: {
    LMap,
    LTileLayer,
    LControl,
    Sidebar,
    HeatsLegend,
    Info
  },

  data(){
    return {
      tileLayer: {
        //url: 'https://api.mapbox.com/styles/v1/devafatek/ckfc8pw7394sr19mwqsj0vcqr/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGV2YWZhdGVrIiwiYSI6ImNrOHd5and3czAxZXczbXF6ODJuM3I2OTcifQ.mjAJVjob0WYyRMmoOESq2w',
        url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      },
      zoom: 16,
      center: [39.839414, 32.823932],
      options: {
        zoomControl: false,
        minZoom: 8,
        maxZoom: 18
      },

      map: null,
      
      devices: [
        {id: 1, lat: 39.84253315181488, lng: 32.81835079193116, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},
        {id: 2, lat: 39.84175880481816, lng: 32.816934585571296, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},
        {id: 3, lat: 39.84195651126451, lng: 32.8198528289795, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},
        {id: 4, lat: 39.840852642989, lng: 32.81684875488282, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},
        {id: 5, lat: 39.841083303498216, lng: 32.81991720199586, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},
        {id: 6, lat: 39.84029246424088, lng: 32.81856536865235, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},

        {id: 7, lat: 39.842928559679244, lng: 32.821977138519294, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},
        {id: 8, lat: 39.84225306986668, lng: 32.82326459884644, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},
        {id: 9, lat: 39.84129748756303, lng: 32.82330751419068, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},
        {id: 10, lat: 39.84039131964614, lng: 32.8218698501587, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},
        {id: 11, lat: 39.83941923531398, lng: 32.82199859619141, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},
        {id: 12, lat: 39.83879313981464, lng: 32.82026052474976, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},

        {id: 13, lat: 39.83940275918977, lng: 32.81867265701295, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},
        {id: 14, lat: 39.84065493336441, lng: 32.82506704330445, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},
        {id: 15, lat: 39.839649900638, lng: 32.82506704330445, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},
        {id: 16, lat: 39.838727234693174, lng: 32.823736667633064, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},
        {id: 17, lat: 39.84332396526659, lng: 32.825195789337165, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},
        {id: 18, lat: 39.842681430030844, lng: 32.826976776123054, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []},
        {id: 19, lat: 39.84154462219228, lng: 32.8268051147461, heat: 0, lastUpdated: this.$moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss'), events: []}      
      ],

      /*
      markerGroups: {
        high: {
          color: 'red',
          markers: []
        },
        medium: {
          color: 'orange',
          markers: []
        },
        low: {
          color: 'green',
          markers: []
        }
      },
      */

      markers: {

      },

      messages: [],
      infoMessages: [],

      legend: [
        {color: '#fe4848', minimum: 80},
        {color: '#fe6c58', minimum: 60},
        {color: '#fe9068', minimum: 40},
        {color: '#fed686', minimum: 20},
        {color: 'green', minimum: 0}
      ]
    }
  },

  watch: {
    '$store.state.map.simulation.active': function(val){
      if(val){
        this.startSimulation()
      }
      else {
        this.stopSimulation()
      }
    }
  },

  methods: {
    mapReady(map) {
      this.map = map
      this.$store.commit('map/setMap', map)
      map.on('click', function(e){
        console.log('latitude: ', e.latlng.lat)
        console.log('longitude: ', e.latlng.lng)
      })

      this.attachMarkers()
    },

    attachMarkers(){
      let self = this;
      for (let device of this.devices){
        var priority = ''
        let color = ''
        for (let legend of this.legend){
          if(device.heat >= legend.minimum){
            color = legend.color
            break
          }
        }
        /*
        if (device.heat > 66){
          priority = 'high'
          color = this.markerGroups.high.color
        } else if (device.heat > 33){
          priority = 'medium'
          color = this.markerGroups.medium.color
        } else {
          priority = 'low'
          color = this.markerGroups.low.color
        }
        */

        /*
        L.markerClusterGroup({
          iconCreateFunction: function(cluster) {
            return L.divIcon({
              html: '<div class="marker-cluster-dumpsters-collected"><span>' + cluster.getChildCount() + '</span></div>',
              className: 'marker-cluster marker-cluster-collected',
              iconSize: L.point(40, 40)
            });
          }
        });
        */

        const svgTemplate = `
        <div class="gps_ring ${device.heat > 40 ? 'alert' : ''}" style="border-color: ${color}"></div>`;

        /**
         const svgTemplate = `
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="marker">
          <path fill-opacity=".25" d="M16 32s1.427-9.585 3.761-12.025c4.595-4.805 8.685-.99 8.685-.99s4.044 3.964-.526 8.743C25.514 30.245 16 32 16 32z"/>
          <path stroke="#fff" fill="${color}" d="M15.938 32S6 17.938 6 11.938C6 .125 15.938 0 15.938 0S26 .125 26 11.875C26 18.062 15.938 32 15.938 32zM16 6a4 4 0 100 8 4 4 0 000-8z"/>
        </svg>
        `
         */



        let marker = L.marker([device.lat, device.lng], {
          icon: L.divIcon({
            className: "marker",
            html: svgTemplate,
            iconSize: [40, 40],
            iconAnchor: [12, 24],
            popupAnchor: [7, -16],
          })
        })

        var popupContent = `
        <div class="card">
            <div class="card-body">
              <table>
                <tr>
                  <td class="text-bold font-bold">ID</td>
                  <td>
                    <span class="badge badge-light-success" style="float:left">
                      ${device.id}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="text-bold font-bold">Sıcaklık</td>
                  <td>
                    <span class="badge badge-light-success" style="float:left">
                      ${device.heat}℃
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="text-bold font-bold">Son Güncellenme</td>
                  <td>
                    <span class="badge badge-light-success" style="float:left">
                      ${this.$moment(device.lastUpdated).fromNow()}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
        </div>
        `
        const popupOptions = {
            'maxWidth': '500',
            'width' : '250',
            'height' : '300',
            'className': 'mapPopup'
        };

        marker.bindPopup(popupContent, popupOptions).on('click', function(e){
          console.log('marker clicked')
          self.map.setView(e.target.getLatLng())
        })

        marker.addTo(this.map)

        this.markers[device.id] = marker
      }
    },

    updateMarkers(){
      this.map.eachLayer(function(layer){
        if (layer instanceof L.Marker){
          layer.remove()
        }
      })

      this.attachMarkers()
    },

    startSimulation(){
      let self = this;
      let sidebar = this.$store.getters['map/getSidebarObject']
      sidebar.close()
      for (let i = 0; i < this.devices.length; i++){
        let device = this.devices[i]
        let interval = setInterval(() => {
          let randomNumber = Math.floor(Math.random() * 10) + 1;
          let now = self.$moment().format('YYYY-MM-DD HH:mm:ss')
          device.heat += randomNumber
          device.events.push({
            time: now,
            increase: randomNumber,
            heat: device.heat
          })
          device.lastUpdated = now

          self.$set(self.devices, i, device)

          self.messages.push({
            id: device.id,
            time: now,
            increase: randomNumber,
            heat: device.heat
          })

          self.infoMessages = self.messages.slice(-5).reverse()

          if (device.heat > 90){
            clearInterval(interval)
            L.circle([device.lat, device.lng], {
              color: 'red',
              fillColor: '#f03',
              fillOpacity: 0.5,
              radius: 100
            }).addTo(this.map)
          }
          this.updateMarkers()
        }, i * 750)
      }
    },

    stopSimulation(){
      this.devices = this.devices.map(device => {
        device.heat = 0
        return device
      })
      this.updateMarkers()
    },

    showDevice(device){
      this.map.setView([device.lat, device.lng])
      this.markers[device.id].openPopup()
    }
  }
}

</script>

<style>
.leaflet-container {
  font-family: inherit;
}
.vue2leaflet-map .leaflet-container{
  font-family: inherit;
  height: 100vh;
  width: 100vw;
}
.gps_ring { 
  border: 3px solid #999;
  -webkit-border-radius: 30px;
  height: 15px;
  width: 15px;       
  /*opacity: 0.0*/
}
.gps_ring.alert {
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite; 
}

@keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.5;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.5;}
}
@-webkit-keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.5;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.5;}
}

</style>
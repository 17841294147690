<template>
    <div class="heats-legend p-2">
        <div class="row mb-2" v-for="l in legend">
            <div class="flex">
                <div class="mr-2" :style="`width:20px;height:20px;background: ${l.color}`"></div>
                <div class="text-left w-1/2">{{l.minimum}} ℃</div>
                <div class="text-right italic bold">({{ getCounts(l.minimum) }})</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['legend', 'devices'],

    data(){
        return {
        }
    },

    watch: {
        devices: function(newVal, oldVal){

        }
    },

    methods: {
        getCounts(minimum){
            if (this.devices.length == 0) return 0;
            if (minimum == 0){
                return this.devices.filter(d => d.heat < 20).length;
            } else if (minimum == 20){
                return this.devices.filter(d => d.heat >= 20 && d.heat < 40).length;
            } else if (minimum == 40){
                return this.devices.filter(d => d.heat >= 40 && d.heat < 60).length;
            } else if (minimum == 60){
                return this.devices.filter(d => d.heat >= 60 && d.heat < 80).length;
            } else if (minimum == 80){
                return this.devices.filter(d => d.heat >= 80).length;
            }
        }
    }
}
</script>

<style>
.heats-legend {
    background-color: rgba(255, 255, 255, 0.95);
    /*padding: 20px;*/
    width: 120px;
    word-break: break-all;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
}
</style>
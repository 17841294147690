<template>
    <div :class="isPlaying ? 'simulation-started' : 'simulation-not-started'">
        <div class="play-toggle" @click="startSimulation">
            <div class="play-circle">
                <i class="play-icon"></i>
            </div>
            <span class="" v-if="!isPlaying">
                <i>Simülasyonu</i><br>
                <strong>Başlat</strong>
            </span>
            <span class="" v-else>
                <i>Simülasyon</i><br>
                <strong>Başlatıldı</strong>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                //isPlaying: false
            }
        },

        computed: {
            isPlaying(){
                return this.$store.getters['map/getSimulationActive']
            }
        },

        methods: {
            startSimulation(){
                this.$store.commit('map/setSimulationActive', !this.isPlaying)
            }
        }
    }
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Roboto:400,400i,900);
@-webkit-keyframes scaleIn {
  to {
    transform: scale(1);
  }
}
@keyframes scaleIn {
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes slideIn {
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideIn {
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
html, body {
  height: 100%;
}

body {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", sans-serif;
}

.play-toggle {
  position: relative;
  cursor: pointer;
}
.play-toggle .play-circle, .play-toggle .play-circle:before, .play-toggle .play-circle:after, .play-toggle span {
  display: inline-block;
  vertical-align: middle;
}
.play-toggle span {
  color: #0274FA;
  margin-left: 1rem;
  font-size: 1.2rem;
  transition: transform 350ms ease;
}
.play-toggle span i, .play-toggle span strong {
  display: inline-block;
  opacity: 0;
  transform: translate3d(-1rem, 0, 0);
  -webkit-animation: slideIn 400ms ease forwards;
          animation: slideIn 400ms ease forwards;
}
.play-toggle span i {
  color: rgba(2, 116, 250, 0.7);
  -webkit-animation-delay: 700ms;
          animation-delay: 700ms;
}
.play-toggle span strong {
  font-size: 1.25em;
  font-weight: 900;
  letter-spacing: 1px;
  -webkit-animation-delay: 750ms;
          animation-delay: 750ms;
}
.play-toggle .play-circle, .play-toggle .play-circle:before, .play-toggle .play-circle:after {
  position: relative;
  height: 4rem;
  width: 4rem;
  background: #0274FA;
  border-radius: 50%;
  transform: scale(0);
  -webkit-animation: scaleIn 500ms cubic-bezier(0.075, 0.82, 0.165, 1) 500ms forwards;
          animation: scaleIn 500ms cubic-bezier(0.075, 0.82, 0.165, 1) 500ms forwards;
}
.play-toggle .play-circle:before, .play-toggle .play-circle:after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  opacity: 0.04;
}
.play-toggle .play-circle:before {
  height: 8rem;
  width: 8rem;
  margin-left: -4rem;
  margin-top: -4rem;
}
.play-toggle .play-circle:after {
  height: 12rem;
  width: 12rem;
  margin-left: -6rem;
  margin-top: -6rem;
}
.play-toggle .play-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0274FA;
  border-radius: 50%;
  box-shadow: 0 3px 24px rgba(0, 0, 0, 0.3);
  transition: transform 350ms ease;
}
.simulation-not-started .play-toggle .play-icon:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 15px solid white;
  border-bottom: 10px solid transparent;
}
.play-toggle:hover .play-icon {
  transform: scale(1.1);
}
.play-toggle:hover span {
  transform: translateX(0.3rem);
}

.simulation-started .play-toggle .play-icon {
    background: #c00101
}
.simulation-started .play-toggle .play-circle, .simulation-started .play-toggle .play-circle:before, .simulation-started .play-toggle .play-circle:after {
    background: #c00101
}
.simulation-started .play-toggle span {
    color: #c00101
}
.simulation-started .play-toggle span i {
  color: rgba(192, 1, 1, 0.7);
}
</style>
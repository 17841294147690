import Vue from 'vue'
import App from './App.vue'

import store from './store'

Vue.config.productionTip = false
Vue.prototype.$store = store

import moment from 'moment';
moment.locale('tr');
Vue.prototype.$moment = moment;

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')

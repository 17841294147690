<template>
  <div id="app">
    <!--
    <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    -->
    <dashboard-map/>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import DashboardMap from './views/DashboardMap.vue'
import './style.css'

export default {
  name: 'App',
  components: {
    //HelloWorld,
    DashboardMap
  }
}
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
  width: 100vw;
  height: 100vh;
}
body {
  margin: 0;
}
</style>
